import { FunctionComponent, InputHTMLAttributes, useEffect, useState } from "react";
import TextInput from "./TextInput";
import styled from "styled-components";
import { isEmpty } from "../utils";

//Spec:
//https://www.figma.com/file/UwZXypeCGS6TGrveckqOOU/tokuty?type=design&node-id=4964-109062&mode=design&t=113laKbAFV2BEylV-4
interface NumberFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  allowDecimal?: boolean;
  minValue?: number;
  maxValue?: number;
  error?: boolean;
  onValueChange?: (value: number | null) => void;
} 

const LocalTextInput = styled(TextInput)`
  text-align: left;
  width: 60px;
  padding-left: 10px;
`;

const NumberField: FunctionComponent<NumberFieldProps> = ({
  allowDecimal,
  minValue,
  maxValue,
  onValueChange,
  value,
  error,
  ...props
}) => {
  //This is necessary because an input like Number("123.") returns 123, so decimals are lost
  const [actualValue, setActualValue] = useState<string>("");
  const isWithinRange = (value: number) => {
    if (minValue && value < minValue)
      return false;
    
    if (maxValue && value > maxValue)
      return false;
    
    return true;
  }
  const handleChange = (newValue: string) => {
    const numRegex = allowDecimal ? /^(\d*\.?\d*)$/ : /^\d*$/;
    const isValidNum = numRegex.test(newValue) && isWithinRange(parseFloat(newValue));
  
    if (isValidNum || isEmpty(newValue)) {
      onValueChange?.(
        isEmpty(newValue) ? null : Number(newValue)
      );
      setActualValue(newValue);
    }
  };

  useEffect(() => {
    setActualValue(value ? String(value) : "");
  }, [value])

  return (
      <LocalTextInput 
        onTextChange={handleChange}
        value={actualValue ?? ""}
        error={error}
        {...props}
      />
  )
}

export default NumberField;